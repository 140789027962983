
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import { DateTime } from "luxon"
import DateTag from "packs/models/DateTag"
import Util from "packs/utils/Util"
import RoomHistoryLargeWrapper from "packs/models/RoomHistoryLargeWrapper"
import RoomHistoryMediumWrapper from "packs/models/RoomHistoryMediumWrapper"
import RoomHistorySmallWrapper from "packs/models/RoomHistorySmallWrapper"
import RoomHistory from "packs/models/RoomHistory"

const roomStorage = new RoomStorage()

@Options({
    components: { UserFileImage, Dropdown },
})
export default class UserStatusCell extends Vue {
    @Prop()
    room: Room
    @Prop()
    mem: RoomMember
    @Prop()
    showIcon: boolean
    @Prop()
    memindex: number

    // data:

    Util = Util
    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    selectedRoom = this.rm.currentRoom
    roomId = null
    step: number = -1

    updatedAtTextStep1 = ``
    updatedAtTextStep2 = ``
    updatedAtTextStep3 = ``

    mounted() {
        this.updateStep()
    }

    @Emit("click")
    click(room) {
        return room
    }

    public getImgUrl() {
        switch (this.step) {
            case 0:
                return `status0_grey`
            case 1:
                return `status0100`
            case 2:
                return `status0200`
            case 3:
                return `status01000`
                break
        }
    }

    public getStatusText() {
        switch (this.step) {
            case 0:
                return `確認待ち`
            case 1:
                return `開封済み`
            case 2:
                return `入室済み`
            case 3:
                return `共有済み`
                break
        }
    }

    @Watch("room", { deep: true })
    public updateStep(): number {
        if (this.mem.role == 100) {
            this.step = 3
            return
        }

        const histLarge = this.getCurrentHistories()
        Logger(`hists: ${Util.output(histLarge)}`)

        if (this.room.shared_schedule_member_ids.includes(this.mem.user_id)) {
            this.step = 3
            if (!histLarge || !histLarge.schedule) return
            const share = histLarge.schedule.share
            if (share) this.updatedAtTextStep3 = DateTag.getTimeStringSlashFromSeconds(share.last_at)
            return
        }

        if (histLarge) {
            if (histLarge.schedule.share) {
                this.getUpdatedAtText(3)
                this.step = 3
                return 3
            } else if (histLarge.room.enter) {
                this.getUpdatedAtText(2)
                this.step = 2
                return 2
            } else if (histLarge.mail.create_room.open) {
                this.getUpdatedAtText(1)
                this.step = 1
                return 1
            }
        }
        this.step = 0
        return
    }

    getUpdatedAtText(step: number) {
        const histLarge = this.getCurrentHistories()
        if (step == 1) {
            const open = histLarge.mail.create_room.open
            if (open) this.updatedAtTextStep1 = DateTag.getTimeStringSlashFromSeconds(open.last_at)
        } else if (step == 2) {
            // this.updatedAtTextStep1 = DateTag.getTimeStringSlashFromSeconds(histLarge.mail.create_room.open.last_at)
            const enter = histLarge.room.enter
            if (enter) this.updatedAtTextStep2 = DateTag.getTimeStringSlashFromSeconds(enter.last_at)
            this.getUpdatedAtText(1)
        } else if (step == 3) {
            const share = histLarge.schedule.share
            if (share) this.updatedAtTextStep3 = DateTag.getTimeStringSlashFromSeconds(share.last_at)
            this.getUpdatedAtText(2)
        }
    }

    /**
     * このユーザーの招待ユーザー情報を取得します。
     */
    public getCurrentHistories(): RoomHistoryLargeWrapper {
        const usersHist = this.room.progress_member_dic || []
        // return invs.find(u => u.invited_user_id == this.mem.user_id)
        return usersHist[this.mem.user_id]
    }

    // public updatedAtText(): string {
    //     const hists = this.getCurrentHistories()
    //     if (!hists) return ``
    //     return DateTag.getTimeStringSlashFromSeconds(histLarge)
    // }

    // 指定されたUserにリマインドメールを送信します。
    public remindUser(user: RoomMember) {
        this.rm.startProgress()
        RoomMember.sendRemind(user.user_id, this.room.id).then(e => {
            this.rm.endProgress()
            // gtagClick(
            //     `進捗リマインド ${this.getStatusText()}`,
            //     `${user.email}宛 最終アクション:${this.updatedAtText()}`
            // )
        })
    }

    public createFeedback() {
        Logger(`feedbackを作成します.`)
        this.$emit(`createFeedback`, this.mem)
        // this.$vfm.open(`CreateFeedbackModal`)
    }

    deletedAttachment() {}

    clickFormMember() {
        this.$emit(`clickFormMember`, this.mem)
    }

    get telephoneNum() {
        if (!this.mem) return ``
        let results = this.mem.answer_results
        if (Util.isBlank(results)) return ``
        let telDic = results.attendee1_tel
        if (Util.isBlank(telDic)) return ``
        return telDic.value
    }
}
