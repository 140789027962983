
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import Util from "packs/utils/Util"
import draggable from "vuedraggable"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import InputGroupNamespaceModal from "packs/pages/link/modals/InputGroupNamespaceModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import OneConditionContent from "packs/pages/link/parts/room_settings/OneConditionContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import FormField from "packs/models/FormField"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormCreateManager from "packs/models/FormCreateManager"
import AssignCondition from "packs/models/AssignCondition"
import AnswerCondition from "packs/models/AnswerCondition"
import AnswerConditionGroup from "packs/models/AnswerConditionGroup"

@Options({
    components: {
        UserIcon,
        MdTextfieldTag,
        InputGroupNamespaceModal,
        CheckboxTag,
        SelectTag,
        FormFieldContent,
        draggable,
        AnswerCondition,
        TextfieldTag,
        TextareaTag,
        OneConditionContent,
    },
})
export default class ConditionSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    fcm = FormCreateManager
    viewType = `list` // list / detail

    Util = Util
    AssignCondition = AssignCondition
    AnswerCondition = AnswerCondition
    fieldPublicIdDic = {}
    updateTime = Util.getSec()

    fields: FormField[] = null
    systemUpdatedAt = Util.getSec()
    drag: boolean = false

    selectableValues: string[] = []
    // acond = []
    edittingCondition: AssignCondition = null
    edittingCondIndex: number = null // condition index
    edittingCondgIndex: number = null // group index
    newTextValue = ``
    showNewAnscondField = false

    mounted() {
        Logger(`${funcName()}`)
        this.fields = this.rcm.fields || []
        for (let _f of this.fields) {
            this.fieldPublicIdDic[_f.public_id] = _f
        }

        // this.acond = this.fcm.aform.assign_conditions || []
        this.selectableValues = this.fields.map(field => field.field_name)
    }

    editCondition(cond: AssignCondition, index: number) {
        Logger(`${funcName()} index:${index} cond:${cond?.id}`)
        if ((cond.answer_condition_groups || []).length == 0) {
            cond.answer_condition_groups = [AnswerConditionGroup.createDefault()]
        } else if ((cond.answer_condition_groups[0].answer_conditions || []).length == 0) {
            cond.answer_condition_groups[0].answer_conditions = [AnswerCondition.createDefault()]
        }
        this.edittingCondition = AssignCondition.copy(cond)
        this.edittingCondIndex = index
        this.edittingCondgIndex = 0
        this.viewType = `detail`
    }

    removeCondition(cond: AssignCondition, condindex: number) {
        Logger(`${funcName()} condindex:${condindex} cond:${cond?.id}`)
        let conds = this.fcm.aform.assign_conditions || []
        conds.splice(condindex, 1)
        conds = AssignCondition.updateIndexNum(conds)
        this.fcm.updateConditions(conds)
        this.endEdit()
    }

    addNewCondition() {
        Logger(`${funcName()} `)
        let acond = AssignCondition.createDefault()
        this.edittingCondition = acond
        let conds = this.fcm.aform.assign_conditions || []
        conds.push(acond)
        conds = AssignCondition.updateIndexNum(conds)
        this.edittingCondIndex = conds.length - 1
        this.edittingCondgIndex = 0
        this.fcm.updateConditions(conds)
        this.viewType = `detail`
    }

    endEdit() {
        Logger(`${funcName()} `)
        this.edittingCondition = null
        this.edittingCondIndex = null
        this.edittingCondgIndex = 0
        this.viewType = `list`
    }

    selectFieldValue(e, index_arr) {
        Logger(`${funcName()} ${e}`)
        let [acgindex, acondindex] = index_arr || [0, 0]
        let anscond = this.edittingCondition.answer_condition_groups[acgindex].answer_conditions[acondindex]
        let f = this.fields.find(field => field.field_name == e)
        Logger(`${funcName()} f:${f.field_name} ${f.public_id}, anscond: field_public_id:${anscond?.field_public_id}`)
        if (Util.isPresent(anscond.field_public_id) && anscond.field_public_id != f.public_id) {
            // 設定していたfieldと異なるため、alertを表示させ、よい場合はリセットします.
            if (confirm(`質問を変更すると、設定されていた条件がリセットされます。よろしいですか？`)) {
                anscond.compare_type = `equal`
                anscond.text_value_arr = []
            } else {
                return
            }
        }
        anscond.field_public_id = f.public_id

        this.updateTime = Util.getSec()
    }

    selectConditionType(e, index_arr) {
        Logger(`${funcName()} type:${e}`)
        let [acgindex, acondindex] = index_arr || [0, 0]
        let acond = this.edittingCondition.answer_condition_groups[acgindex].answer_conditions[acondindex]
        let objkey = Util.findKeyByValue(AnswerCondition.TYPE_JP, e)

        acond.compare_type = objkey
        acond.text_value_arr ||= []
        if (
            AnswerCondition.isTextType(this.fieldPublicIdDic[acond.field_public_id].field_type) &&
            (acond.text_value_arr || []).length == 0
        ) {
            acond.text_value_arr.push(``)
        }
        this.updateTime = Util.getSec()
    }

    selectType(e) {
        Logger(`${funcName()} type:${e}`)
    }

    addNewRule(type = `or`, acond = null) {
        Logger(`${funcName()} `)
        if (type == `or`) {
            // テキストタイプの場合、空の値を追加します.
            if (acond && AnswerCondition.isTextType(this.fieldPublicIdDic[acond.field_public_id].field_type)) {
                this.edittingCondition.answer_condition_groups[this.edittingCondgIndex].answer_conditions[
                    this.edittingCondgIndex
                ].text_value_arr.push(``)
            }
            this.showNewAnscondField = true
        } else {
            this.edittingCondition.answer_condition_groups[this.edittingCondgIndex].answer_conditions.push(
                AnswerCondition.createDefault()
            )
        }
    }

    removeRule(acond, valueindex) {
        Logger(`${funcName()} ${acond} ${valueindex}`)
        acond.text_value_arr.splice(valueindex, 1)
    }

    cancelRule() {
        Logger(`${funcName()}`)
        this.endEdit()
    }

    saveRule() {
        Logger(`${funcName()}`)
        let conds = this.fcm.aform.assign_conditions || []
        if (Util.isBlank(conds)) {
            conds = [this.edittingCondition]
        } else {
            conds = conds.map(cond => (cond.public_id === this.edittingCondition.public_id ? this.edittingCondition : cond))
        }
        conds = AssignCondition.updateIndexNum(conds)

        this.fcm.updateConditions(conds)
        this.endEdit()
    }

    selectNoMatchDestinationType(e) {
        Logger(`${funcName()} ${e}`)
        let objkey = Util.findKeyByValue(AssignCondition.DEST_TYPES_JP, e)
        Logger(`${funcName()} ${e} ${objkey}`)
        this.fcm.aform.no_match_destination_type = objkey
    }

    selectDestinationType(e) {
        let objkey = Util.findKeyByValue(AssignCondition.DEST_TYPES_JP, e)
        Logger(`${funcName()} ${e} ${objkey}`)
        this.edittingCondition.destination_type = objkey
    }

    updateNoMatchDestinationValue(e) {
        Logger(`${funcName()} ${e}`)
        this.fcm.aform.no_match_destination_value = e
    }

    updateDestinationValue(e) {
        Logger(`${funcName()} ${e}`)
        this.edittingCondition.destination_value = e
    }

    inputCondValue(e, index_arr) {
        Logger(`${funcName()} ${e} ${index_arr}`)
        let [acgindex, acondindex, valueindex] = index_arr || [0, 0, 0]
        let arr = this.edittingCondition.answer_condition_groups[acgindex].answer_conditions[acondindex].text_value_arr || []
        // if (arr[valueindex]) {
        //     arr[valueindex] = e
        // } else {
        //     arr.push(e)
        // }
        Logger(`${funcName()} ${Util.output(arr)} ${valueindex}`)
        arr[valueindex] = e

        this.edittingCondition.answer_condition_groups[this.edittingCondgIndex].answer_conditions[acondindex].text_value_arr = arr
        this.showNewAnscondField = false
    }

    selectCondValue(e, index_arr) {
        Logger(`${funcName()} ${e} ${index_arr}`)
        let [acgindex, acondindex, valueindex] = index_arr || [0, 0, 0]

        let arr = this.edittingCondition.answer_condition_groups[acgindex].answer_conditions[acondindex].text_value_arr || []
        if (arr[valueindex]) {
            arr[valueindex] = e
        } else {
            arr.push(e)
        }
        this.edittingCondition.answer_condition_groups[acgindex].answer_conditions[acondindex].text_value_arr = arr
        this.showNewAnscondField = false
    }

    clickUp(cond: AssignCondition) {
        // 順番を並び替えます.
        let conds = [...this.fcm.conds]
        let _cIndex = conds.findIndex(c => c.public_id == cond.public_id)
        conds.splice(_cIndex, 1)
        conds.splice(_cIndex - 1, 0, cond)

        let newConds = AssignCondition.updateIndexNum(conds)
        this.fcm.updateConditions(newConds)
    }

    clickDown(cond: AssignCondition) {
        // 順番を並び替えます. [0,1,2,3,4]
        let conds = [...this.fcm.conds]
        let _cIndex = conds.findIndex(c => c.public_id == cond.public_id)
        conds.splice(_cIndex, 1)
        conds.splice(_cIndex + 1, 0, cond)
        // 番号を振り直します.
        let newConds = AssignCondition.updateIndexNum(conds)
        this.fcm.updateConditions(newConds)
    }
}
