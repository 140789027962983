// import "jquery"
// import { resetDisplayList } from "./calendarView"
// import "bootstrap"
// global.$ = global.jQuery = require("jquery")

import Util from "./utils/Util"

// import Util from "./utils/Util"
declare var gtag: any
declare var $: any

let isProduction = process.env.NODE_ENV == "production" ? true : false

// const isProduction = false
// console.log(`common called.`)
// isProduction = true

// export function fadeOutAll() {
//     $(".black_bg").fadeOut(500)
//     $(".hidden_content").fadeOut(500)
//     $(".white_bg").fadeOut(500)
//     $("body")
//         .removeClass("noscroll")
//         .css("top", 0)
// }

export function Logger(str) {
    if (!isProduction) {
        console.log(`${str}`)
    }
}

export function funcName() {
    try {
        let error = {} as any

        try {
            throw new Error("")
        } catch (e) {
            error = e
        }
        // IE9 does not have .stack property
        if (error.stack === undefined) {
            return ""
        }
        let stackTrace = error.stack.split("\n")[2]

        if (/ /.test(stackTrace)) {
            stackTrace = stackTrace.trim().split(" ")[1]
        }
        // Logger(`stackTrace:${stackTrace}`)
        if (!stackTrace.includes(`Object`)) {
            return stackTrace
        }

        if (stackTrace && stackTrace.indexOf(".") > -1) {
            stackTrace = stackTrace.split(".").slice(1).join(".")
        }
        return stackTrace
    } catch (e) {
        return null
    }
}

// exports.fadeOutAll = fadeOutAll

export function sleep(waitMilliSeconds): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, waitMilliSeconds))
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

// export function hideTooltip(_this: string) {
//     setTimeout(function() {
//         $(_this).tooltip("hide")
//     }, 1000)
// }

export function copyToClipboard(str) {
    // const el = document.createElement("textarea")
    // el.value = str
    // document.body.appendChild(el)
    // if (window.navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //     // iOS用.
    //     let editable: string = el.contentEditable
    //     let readOnly = el.readOnly
    //     el.contentEditable = "true"
    //     el.readOnly = false
    //     var range = document.createRange()
    //     range.selectNodeContents(el)
    //     var sel = window.getSelection()
    //     sel.removeAllRanges()
    //     sel.addRange(range)
    //     el.setSelectionRange(0, 999999)
    //     el.contentEditable = editable
    //     el.readOnly = readOnly
    // } else {
    //     el.select()
    // }

    // document.execCommand("copy")
    // document.body.removeChild(el)
    navigator.clipboard.writeText(str).then(
        function () {
            Logger(`Copying to clipboard was successful!`)
        },
        function (err) {
            Logger(`Could not copy text: ${err}`)
        }
    )
}

export function zeroPadding(num, length = 2) {
    return ("0000000000" + num).slice(-length)
}

//
export function getLang(): string {
    if (navigator.languages != undefined) {
        // Logger(`${funcName()} navigator.languages:${Util.output(navigator.languages)}`)
        return navigator.languages[0]
    }
    return navigator.language || "en"
}

/**
 * 小数点以下をprecisionの数で丸めます round(1.0245, 2) -> 1.02
 * @param number [number]
 * @param precision [number]
 */
export function round(number: number, precision: number) {
    let shift = function (number, precision, reverseShift) {
        if (reverseShift) {
            precision = -precision
        }
        var numArray = ("" + number).split("e")
        return +(numArray[0] + "e" + (numArray[1] ? +numArray[1] + precision : precision))
    }
    return shift(Math.round(shift(number, precision, false)), precision, true)
}

var timeSincePageLoad = -1
var didStart = false

export function startMeasurement() {
    if (window.performance) {
        timeSincePageLoad = Math.round(performance.now())
        didStart = true
    } else {
        timeSincePageLoad = -1
    }
}

export function stopMeasurement(eventName, eventCategory) {
    if (didStart) {
        var loadingTotalTime = Math.round(performance.now()) - timeSincePageLoad
        // Logger("計測を停止しました: " + loadingTotalTime)
        gtag("event", "timing_complete", {
            name: eventName,
            value: loadingTotalTime,
            event_category: eventCategory,
        })
        timeSincePageLoad = -1
        didStart = false
    }
}

export function decodeQueryParam(p: string) {
    if (!p) return ``
    let _p = p.replace(/\+/g, " ")
    _p = decodeURIComponent(_p.replace(/\%20/g, " "))
    return _p.replace(/\%20/g, " ")
}
