
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import axios from "axios"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import RangefieldTag from "packs/components/forms/RangefieldTag.vue"
import Notice from "packs/models/Notice"
import Util from "packs/utils/Util"
import Const from "packs/utils/Const"

import { Logger } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        MdTextfieldTag,
        TextareaTag,
        RadioButtonTag,
        RangefieldTag,
    },
})
export default class RegistrationForm extends Vue {
    @Prop()
    isDev

    @Prop()
    devType

    // data
    isSP = isMobile(window.navigator).phone
    notice = Notice
    Const = Const
    Util = Util

    loading = false

    rm = RoomManager

    page = 1

    // 日程調整で聞きたいことを入れます.
    sections = {
        "1": `1`,
        "2": `1`,
        "10000": null,
    }
    content = ``
    validName = true
    validCompanyName = true
    validShortName = true
    validTel = true
    validCompanyMail = true
    validOccupation = true
    validNamespace = true

    namespace = ``
    ngReasons: string[] = null
    canRegister = null

    additionalInfo = { tel: null, occupation: null }

    public created() {}

    public next() {
        const param = this.rm.userInfo
        this.checkName()
        if (!param.name) {
            Logger(`必須フィールドに入力がありません`)
            return
        }

        // 未確認で会社の場合のみフィールドチェック.
        if (param.group_status == 1 && param.is_company) {
            this.checkCompanyName()

            if (!param.company_name) {
                Logger(`必須フィールドに入力がありません`)
                return
            }
        }

        // 既に利用を開始している招待ユーザーのため、ここで入力を終了させます。
        if (this.rm.userInfo.group_status >= Const.STATUS_FREE_PLAN()) {
            const type = param.is_company ? 2 : 1
            Logger(`同一ugに招待されたユーザーのため、名前だけ入力したら完了にします。`)
            axios
                .put(`${Util.prefixUrl}/confirm_user_info`, {
                    name: this.rm.userInfo.name,
                    type: type,
                })
                .then(res => {
                    Logger(`res: ${Util.output(res)}`)
                    this.notice.message = `ユーザー情報を登録しました。`
                    // this.notice.message = res.data.message;
                    this.rm.tutorial_phase = res.data.tutorial_phase

                    this.$emit("complete")
                })
        } else {
            this.page = 2
            $(".userFormContent").scrollTop()
        }
    }

    get defaultOption(): string {
        Logger(`default is Compnany : ${this.rm.userInfo.is_company}`)
        if (this.rm.userInfo && this.rm.userInfo.is_company) {
            return `company`
        } else {
            return `personal`
        }
    }

    public inputTel(e) {
        this.sections["10000"] = e
    }

    public inputCompanyMail(e) {
        this.sections["10001"] = e
    }

    public inputOccupation(e) {
        this.additionalInfo["occupation"] = e
    }

    /**
     * 会社 or 個人
     */
    public selectType(e) {
        Logger(`select: ${e}`)
        if (e == `company`) {
            this.rm.userInfo.is_company = true
        } else {
            this.rm.userInfo.is_company = false
        }
    }

    /**
     * 月当たりの日程調整数
     */
    public selectYourScheduleNumPM(op) {
        Logger(`select[1]: ${op.id}`)
        this.sections[`1`] = op.id
    }

    /**
     * 会社規模
     */
    public selectUserNumPerCompany(op) {
        Logger(`select[2]: ${op.id}`)
        this.sections[`2`] = op.id
    }

    /**
     * 月当たりの会社全体の日程調整数
     */
    public selectCompanyScheduleNumPM(op) {
        Logger(`select[3]: ${op.id}`)
        this.sections[`3`] = op.id
    }

    public inputUserName(e) {
        Logger(`inputUserName: ${e}`)
        this.rm.userInfo.name = e
    }

    public checkName() {
        this.validName = this.rm.userInfo.name && this.rm.userInfo.name.length > 0
    }

    public checkCompanyName() {
        this.validCompanyName = this.rm.userInfo.company_name && this.rm.userInfo.company_name.length > 0
    }

    public inputCompanyName(e) {
        Logger(`inputCompanyName: ${e}`)
        this.rm.userInfo.company_name = e
    }

    public inputContent(e) {
        this.content = e
    }

    public checkTel() {
        this.validTel = this.sections["10000"] && this.sections["10000"].length > 0
    }

    public checkCompanyMail() {
        this.validCompanyMail = this.sections["10001"] && this.sections["10001"].length > 0
    }

    public checkOccupation() {
        this.validOccupation = this.additionalInfo.occupation && this.additionalInfo.occupation.length > 0
    }

    public inputNamespace(e) {
        this.namespace = e
        this.checkName()
    }

    public checkNamespace() {
        this.validNamespace = !Util.isBlank(this.namespace)
        if (Util.isBlank(this.namespace)) {
            this.canRegister = false
            this.ngReasons = [`半角英数字3文字以上で、正しく入力してください。`]
            return
        }
        RoomMember.checkNamespace(this.namespace).then(res => {
            if (res.canRegister) {
                this.canRegister = true
            } else {
                this.canRegister = false
                this.ngReasons = res.msgs
            }
        })
    }

    /**
     * 入力したユーザー情報を送信します。問題なければ、更新して調整カレンダーの作成に移ります。
     */
    public sendUserInfo() {
        const param = this.rm.userInfo
        this.checkTel()
        if (!this.sections["10000"]) {
            Logger(`ご連絡先を入力してください。`)
            return
        }

        this.checkCompanyMail()
        if (!this.sections["10001"]) {
            Logger(`企業メールアドレスを入力してください。`)
            return
        }

        this.checkOccupation()
        if (!this.additionalInfo.occupation) {
            Logger(`ご職業を入力してください。`)
            return
        }

        if (!this.content) {
            Logger(`お問い合わせ内容は必須項目です`)
            return
        }

        if (!this.validName) {
            // this.notice.message = `企業URLを正しく入力してください`
            return
        }

        this.checkNamespace()
        if (this.isDev) {
            this.notice.message = `開発モードのため、送信せず閉じます。`
            this.rm.tutorial_phase += 5
            if (this.rm.tutorial_phase >= 15) {
                this.$emit("complete")
            }
            return
        }

        if (!this.canRegister) {
            this.notice.message = `企業URLが利用できません。他のURLに変更してください。`
            return
        }
        Logger(`送信します`)

        if (this.loading) return
        this.loading = true
        this.ngReasons = null

        const type = param.is_company ? 2 : 1

        axios
            .put(`${Util.prefixUrl}/confirm_user_info`, {
                name: this.rm.userInfo.name,
                type: type,
                company_name: this.rm.userInfo.company_name,
                additional_info: this.additionalInfo,
                sections: this.sections,
                content: this.content,
                namespace: this.namespace,
            })
            .then(res => {
                Logger(`res: ${Util.output(res)}`)
                // this.notice.message = res.data.message;
                this.rm.tutorial_phase = res.data.tutorial_phase

                this.$emit("complete")
            })
    }
}
