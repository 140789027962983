import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("img", {
    src: _ctx.getColor(),
    width: _ctx.width ? _ctx.width : 30,
    height: _ctx.width ? _ctx.width * 1.1 : 33,
    class: "pointer",
    style: {"margin-top":"-3px","margin-right":"5px"}
  }, null, 8, _hoisted_1)), [
    [_directive_tooltip, _ctx.getText]
  ])
}