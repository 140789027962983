import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "starContent"
}
const _hoisted_2 = {
  key: 1,
  class: "starContent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.starred)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: "/assets/icons/star_fill_.png",
            width: "22",
            height: "22",
            class: "pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pushStar && _ctx.pushStar(...args)))
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: "/assets/icons/star_blank.png",
            width: "22",
            height: "22",
            class: "pointer",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pushStar && _ctx.pushStar(...args)))
          })
        ]))
  ]))
}