
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, copyToClipboard } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import VueQrcode from "@chenfengyuan/vue-qrcode"
import CopyButton from "packs/components/buttons/CopyButton.vue"

// モデルを読込.
import Room from "packs/models/Room"

@Options({
    components: { TextfieldTag, VueQrcode, CopyButton },
})
export default class UrlWithQRcodeContent extends Vue {
    // data:
    @Prop()
    url: string

    @Prop()
    index: number

    @Prop()
    hideQRcode: boolean

    isSP = isMobile(window.navigator).phone
    showTip = false

    mounted() {}

    public clickTextfield() {
        Logger(`clickTextfield `)
        $(`input.pageLinkUrl${this.index}`).select()
    }

    get qroption() {
        return {
            errorCorrectionLevel: "M",
            maskPattern: 0,
            margin: 10,
            scale: 2,
            width: 300,
            mime: `image/png`,
            color: {
                dark: "#000000FF",
                light: "#FFFFFFFF",
            },
        }
    }
}
