
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import InputGroupNamespaceModal from "packs/pages/link/modals/InputGroupNamespaceModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ScheduleRuleModal from "packs/pages/link/parts/room_settings/ScheduleRuleModal.vue"
import RoomDetailSettings from "packs/pages/link/parts/room_settings/RoomDetailSettings.vue"
import ThanksPage from "packs/pages/schedule/ThanksPage.vue"
import RoomSettingsAvailableTime from "packs/pages/link/parts/room_settings/RoomSettingsAvailableTime.vue"
import SettingsTabContent from "packs/pages/link/parts/room_settings/SettingsTabContent.vue"
import RoomSettingsUseChatContent from "packs/pages/link/parts/room_settings/RoomSettingsUseChatContent.vue"
import FileBoardModal from "packs/pages/link/modals/FileBoardModal.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import RoomSettingsInstantRoom from "packs/pages/link/parts/room_settings/RoomSettingsInstantRoom.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import AvailableScheduleRulesContent from "packs/pages/link/parts/room_settings/AvailableScheduleRulesContent.vue"
import PublicSettingsAttachmentFilesContent from "packs/pages/link/parts/room_settings/room_settings_parts/PublicSettingsAttachmentFilesContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import UserFile from "packs/models/UserFile"

@Options({
    components: {
        UserIcon,
        MdTextfieldTag,
        InputGroupNamespaceModal,
        CheckboxTag,
        SelectTag,
        ScheduleRuleModal,
        RoomDetailSettings,
        ThanksPage,
        RoomSettingsAvailableTime,
        SettingsTabContent,
        RoomSettingsUseChatContent,
        RoomSettingsInstantRoom,
        FileBoardModal,
        UserFileImage,
        IconLock,
        AvailableScheduleRulesContent,
        PublicSettingsAttachmentFilesContent,
    },
})
export default class PublicSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    // @Prop()
    // appo: Appointment

    @Prop() // func
    closePanel

    @Prop() // func
    updateRoomSetting

    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    ruleText = null
    showEditThanksPage = false
    selectedTabName = `public` // public / schedule
    cm = CalendarManager

    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    timeArray = SelectUtil.timeArray
    notice = Notice
    currentFiles = []

    availableScheduleRuls = []

    // showDetail = false

    mounted() {
        gtagPage(`#/room_settings/public_settings`)
        this.checkGroupNamespace()
        this.closePanel()
        this.getRuleText()
        this.checkShowThanksPage()
    }

    selectTab(tabname: string) {
        this.selectedTabName = tabname
        this.rcm.currentTab = null
    }

    @Watch(`rm.userInfo`, { deep: true })
    public checkGroupNamespace() {
        if (!this.rm.userInfo) return
        if (Util.isBlank(this.rm.namespace)) {
            this.$vfm.open(`InputGroupNamespaceModal`)
        } else {
            this.$vfm.close(`InputGroupNamespaceModal`)
        }
    }

    @Watch(`rcm.room`, { deep: true })
    checkShowThanksPage() {
        this.showEditThanksPage = true
        return
        // if (this.room.display_form_timing_type != `open_page`) {
        //     this.showEditThanksPage = false
        //     return
        // }
        // if (
        //     this.room.display_schedule_type == `rules` &&
        //     Util.isPresent(this.room.rule) &&
        //     Util.isPresent(this.ruleText)
        // ) {
        //     this.showEditThanksPage = true
        //     return
        // }
        // if (this.room.display_schedule_type == `never`) {
        //     this.showEditThanksPage = true
        //     return
        // }
        // this.showEditThanksPage = false
    }

    openNamespaceModal() {
        this.$vfm.open(`InputGroupNamespaceModal`)
    }

    inputPageKey(e) {
        this.$emit(`inputPageKey`, e)
    }

    updateNamespace(namespace: string) {
        this.rm.namespace = namespace
        Logger(`namespaceを更新しました: ${namespace} `)
        this.$vfm.close(`InputGroupNamespaceModal`)
    }

    clickUseNoindex() {
        this.$emit(`clickUseNoindex`)
    }

    getDefaultTimingType() {
        let types = SelectUtil.selectFormTimingTypes
        if (!this.rcm.room) return types.find(t => t.type == `with_fix`)

        const currentType = this.rcm.room.display_form_timing_type
        let _t = types.find(t => t.type == currentType)
        if (!_t) _t = types.find(t => t.type == `with_fix`)
        return _t.name
    }

    selectTimingType(typeName: string) {
        let types = SelectUtil.selectFormTimingTypes
        let _t = types.find(t => t.name == typeName)
        this.$emit(`selectTimingType`, _t.type)
    }

    getDefaultDisplayScheduleType() {
        let types = SelectUtil.displayScheduleTypes
        if (!this.rcm.room) return types.find(t => t.type == `anytime`)

        const currentType = this.rcm.room.display_schedule_type
        let _t = types.find(t => t.type == currentType)
        Logger(`${funcName()} currentType: ${Util.output(currentType)}, ${Util.output(_t)}`)
        if (!_t) _t = types.find(t => t.type == `anytime`)
        return _t.name
    }

    selectDisplayScheduleType(typeName: string) {
        let types = SelectUtil.displayScheduleTypes
        let _t = types.find(t => t.name == typeName)
        if (_t.type == `rules_by_ai` && !this.canRulesByAI()) {
            this.rcm.room.display_schedule_type = null
            this.rcm.updateRoom(this.rcm.room)
            sleep(500).then(_ => {
                this.rcm.room.display_schedule_type = `anytime`
                this.rcm.updateRoom(this.rcm.room)
            })

            return
        }
        this.$emit(`selectDisplayScheduleType`, _t.type)
    }

    selectShowVoteType(type: string) {
        this.$emit(`selectShowVoteType`, type)
    }

    selectShowFilterVoteType(type: string) {
        this.$emit(`selectShowFilterVoteType`, type)
    }

    get getSendChannelType() {
        let types = SelectUtil.selectSendChannelTypes
        let defaultType = types.find(t => t.type == `email`)
        if (!this.rcm.room_setting) return defaultType.name

        const currentType = this.rcm.room_setting.send_channel_type
        let _t = types.find(t => t.type == currentType)
        Logger(`${funcName()} currentType: ${Util.output(currentType)}, ${Util.output(_t)}`)
        if (!_t) return defaultType.name
        return _t.name
    }

    selectSendChannelType(typeName: string) {
        Logger(`${funcName()} typename:${typeName}`)
        let types = SelectUtil.selectSendChannelTypes
        let _t = types.find(t => t.name == typeName)
        if (_t) {
            this.rcm.room_setting.send_channel_type = _t.type
            this.rcm.updateRoomSetting(this.rcm.room_setting)
        }
    }

    clickFixType() {
        this.$emit(`clickFixType`)
    }

    getTimingImage() {
        const currentType = this.rcm.room.display_form_timing_type
        if (currentType == `open_page`) {
            return `/assets/meeting/timing_open_page`
        } else if (currentType == `with_fix`) {
            return `/assets/meeting/timing_with_fix`
        } else if (currentType == `after_fix`) {
            return `/assets/meeting/timing_after_fix`
        }
    }

    addRule() {
        Logger(`ruleを設定`)
        gtagClick(`PublicSettings ルールを設定`, ``)
        this.$vfm.open(`ScheduleRuleModal`)
    }

    saveRule(rule: FormField[]) {
        this.$vfm.close(`ScheduleRuleModal`)
        this.$emit(`saveRule`, rule)
        this.getRuleText()
        gtagClick(`PublicSettings ルールを保存`, this.ruleText)
    }

    getRuleText() {
        if (!this.rcm.room || !this.rcm.room.rule) {
            this.ruleText = null
            return
        }
        let text = ``
        let index = 0
        for (let _f of this.rcm.room.rule) {
            if (Util.isBlank(_f.selected_values)) continue

            if (index != 0) text += `、`
            text += `「${_f.field_name}」が「${_f.selected_values[0]}」`
            index += 1
        }
        this.ruleText = text
    }

    editThanksPage() {
        this.editEditableContent(`thanks_page`)
    }

    editProviderEvent() {
        this.editEditableContent(`provider_event`)
    }

    get showChatContent() {
        if (Util.isBlank(this.rm.userInfo)) return
        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_use_chat`)
    }

    showImageBoardModal() {
        Logger(`${funcName()}`)
        this.$vfm.open(`FileBoardModal`)
    }

    selectFile(file: UserFile) {
        Logger(`${funcName()}: ${Util.output(file)}`)
        let rs = this.rcm.room_setting
        rs.attachment_files = [file]
        this.currentFiles = [file]
        this.rcm.updateRoomSetting(rs)
        this.$vfm.close(`FileBoardModal`)
    }

    deleteFiles() {
        Logger(`${funcName()}`)
        let rs = this.rcm.room_setting
        rs.attachment_files = null
        this.currentFiles = null
        this.rcm.updateRoomSetting(rs)
    }

    changeAttachment() {
        Logger(`${funcName()}`)
        let rs = this.rcm.room_setting
        this.currentFiles = rs.attachment_files
        this.$vfm.open(`FileBoardModal`)
    }

    clickAutoDownloadFiles() {
        let rs = this.rcm.room_setting
        if (rs.can_auto_download) {
            rs.can_auto_download = false
        } else {
            rs.can_auto_download = true
        }
        this.rcm.updateRoomSetting(rs)
    }

    get canSendSMS() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_send_sms`, false)
    }

    canRulesByAI() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_display_schedule_rules_by_ai`, true)
    }

    inputInsteadOfOwnerEmail(e) {
        Logger(`${funcName()} e:${e}`)
        let rs = this.rcm.room_setting
        rs.instead_of_owner_email = e
        this.rcm.updateRoomSetting(rs)
    }
}
