
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import FileUtil from "packs/utils/FileUtil"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.

// モデルを読込.
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import Notice from "packs/models/Notice"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {},
})
export default class UserFileImage extends Vue {
    @Prop()
    file: UserFile

    @Prop()
    showDeleteButton: boolean // 表示していいかどうか.

    // narrow/wide/form/owner_modal/square
    // chat系 narrow・・ファイル添付したとき、wide・・メッセージ中に表示されるもの
    // form フォーム上に表示
    //
    @Prop()
    type: string

    @Prop()
    isMe: boolean

    @Prop()
    hideDownloadLink: boolean

    @Prop()
    parentId: string

    @Prop()
    hideToolTip: boolean

    @Prop()
    hideName: boolean

    @Prop()
    showOnlyIcon: boolean

    @Prop()
    width: number

    showDelete: boolean = false
    rm = RoomManager
    truncate = truncate

    public async mounted() {
        // 最新のメッセージが表示されます。
    }

    get isWideType(): boolean {
        return [`wide`, `form`, `owner_modal`].includes(this.type)
    }

    get isChatType(): boolean {
        return [`wide`, `narrow`].includes(this.type)
    }

    /**
     * 添付したファイルを削除.
     */
    public deleteAttachment(file) {
        Logger(`添付ファイルを削除します: ${file.original_filename}`)
        UserFile.delete(file.file_id).then(e => {
            if (e) {
                this.$emit("deleted", file)
                // this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
            } else {
                this.$emit("deleted", null)
            }
        })
    }

    /**
     * ファイルをダウンロード.
     */
    downloadFile() {
        gtagClick(`ファイルDL ${this.type}`, `${this.file.original_filename}`)
        this.rm.startProgress()
        FileUtil.downloadFile(this.file, this.parentId).then(_ => {
            this.rm.endProgress()
        })
    }

    mouseover() {
        this.showDelete = true
    }

    mouseleave() {
        this.showDelete = false
    }

    clickImage() {
        Logger(`モーダルでイメージを開きます.`)
        this.$emit("showPreview", this.file)
    }
}
