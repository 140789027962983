import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ofs" }
const _hoisted_2 = { class: "availableTimePreviewContent" }
const _hoisted_3 = {
  class: "posRe ofs",
  style: {"width":"1000px"}
}
const _hoisted_4 = {
  class: "availableTimeTable arial",
  style: {"margin-left":"92px"}
}
const _hoisted_5 = { style: {"table-layout":"fixed"} }
const _hoisted_6 = {
  key: 0,
  class: "fl w100"
}
const _hoisted_7 = {
  key: 1,
  class: "fl w100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MemberDailyLine = _resolveComponent("MemberDailyLine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _createElementVNode("tr", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hours, (hour, hourindex) => {
              return (_openBlock(), _createElementBlock("th", {
                style: {"width":"60px"},
                key: `${hour}${hourindex}`
              }, _toDisplayString(hour + 8), 1))
            }), 128))
          ])
        ]),
        (_ctx.from == `schedule_custom`)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_MemberDailyLine, {
                ref: `MemberDailyLine`,
                dailyEventsInfo: 
                            _ctx.dailyEvents ? { possible_events: [], events: _ctx.dailyEvents || [], event_readable: true } : null
                        ,
                member: {},
                type: `all`,
                currentTime: _ctx.currentTime,
                myAttendee: _ctx.myAttendee,
                mag_dic: null,
                duration: _ctx.duration,
                pd: _ctx.currentPd,
                onCalcurateOrangeBar: _ctx.calcurateOrangeBar,
                onSelect: _ctx.selectMag
              }, null, 8, ["dailyEventsInfo", "currentTime", "myAttendee", "duration", "pd", "onCalcurateOrangeBar", "onSelect"])
            ]))
          : (_ctx.pdm && _ctx.pdm.sharedMembers.length > 0 && _ctx.dailyMembersEvents)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdm.sharedMembers, (member) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${member.id}${member.user_id}${_ctx.currentPd.start_time}`
                  }, [
                    _createVNode(_component_MemberDailyLine, {
                      ref_for: true,
                      ref: `MemberDailyLine`,
                      dailyEventsInfo: _ctx.dailyMembersEvents[`${member.user_id}`],
                      member: member,
                      type: `all`,
                      currentTime: _ctx.currentTime,
                      myAttendee: _ctx.myAttendee,
                      mag_dic: _ctx.pdm.mag_dic,
                      duration: _ctx.duration,
                      pd: _ctx.currentPd,
                      onCalcurateOrangeBar: _ctx.calcurateOrangeBar,
                      onSelect: _ctx.selectMag
                    }, null, 8, ["dailyEventsInfo", "member", "currentTime", "myAttendee", "mag_dic", "duration", "pd", "onCalcurateOrangeBar", "onSelect"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
        (_ctx.currentPd && _ctx.currentTime && _ctx.currentHour)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "posAb kireiOrangeBg rad3",
              style: _normalizeStyle(`opacity:0.8;width:${_ctx.currentPd.duration}px;left:${
                        100 + (_ctx.currentHour - 8 + _ctx.currentMin / 60) * 60
                    }px;height:${_ctx.barHeight}px;top:23px;`)
            }, null, 4))
          : _createCommentVNode("", true),
        (_ctx.travelTimeBefore > 0 && _ctx.travelTimeBeforeStart)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "posAb bufferYellowBg rad3",
              style: _normalizeStyle(`opacity:0.7;width:${_ctx.travelTimeBefore}px;left:${
                        100 + (_ctx.travelTimeBeforeStart.hour - 8 + _ctx.travelTimeBeforeStart.minute / 60) * 60
                    }px;height:95px;top:23px;`)
            }, [
              _createElementVNode("img", {
                src: "assets/icons/both_arrows.png",
                width: "24",
                height: "16",
                class: "posRe",
                style: _normalizeStyle(`margin-top:44px;margin-left:${(_ctx.travelTimeBefore - 24) / 2}px`)
              }, null, 4)
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.travelTimeAfter > 0 && _ctx.travelTimeAfterStart)
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: "posAb bufferYellowBg rad3",
              style: _normalizeStyle(`opacity:0.7;width:${_ctx.travelTimeAfter}px;left:${
                        100 + (_ctx.travelTimeAfterStart.hour - 8 + _ctx.travelTimeAfterStart.minute / 60) * 60
                    }px;height:95px;top:23px;`)
            }, [
              _createElementVNode("img", {
                src: "assets/icons/both_arrows.png",
                width: "24",
                height: "16",
                class: "posRe",
                style: _normalizeStyle(`margin-top:44px;margin-left:${(_ctx.travelTimeAfter - 24) / 2}px`)
              }, null, 4)
            ], 4))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}