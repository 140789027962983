
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import QuestionsBoxModal from "packs/pages/link/parts/room_summary/QuestionsBoxModal.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDate from "packs/models/PossibleDate"
import VotedRoomManager from "packs/models/VotedRoomManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
        QuestionsBoxModal,
        IconHelp,
        Dropdown,
    },
})
export default class SuggestedRoomsMembersHeader extends Vue {
    // data:

    @Prop() // func
    showRoomModal

    @Prop() // schedule_view / summary
    from: string

    @Prop()
    showMyVoteView: boolean

    @Prop() // w30で指定. 左側
    columnWidthClass: string

    @Prop() // w70で指定. 右側
    cellWidthClass: string

    @Prop() // func
    showMenuModal

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone
    DateTime = DateTime

    loading = false
    Util = Util

    member: RoomMember = null
    maxAttendeesNum: number = -1
    parentRooms: Room[] = null
    displayParamKeys: string[] = []

    now = Util.getSec()

    public mounted() {
        // gtagPage(`#/summary`)
        // this.room = this.rm.currentRoom;
        this.updatePublicRoom()
    }

    @Watch(`vrm.publicRoom`, { deep: true })
    updatePublicRoom() {
        if (this.vrm.publicRoom) {
            this.vrm.displayFields = this.vrm.getDisplayFieldsInPublicRoom(this.vrm.publicRoom.id)
            this.displayParamKeys = (this.vrm.displayFields || []).map(f => f.param_key)
        }
    }

    clickMember(mem: RoomMember) {
        this.member = mem
        let room = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        // 調整ページへ
        if (room) {
            Logger(`SuggestedRooms調整ページが見つかりました.: ${Util.output(room)}`)
            this.$emit(`goRoom`, room)
        }
    }

    clickFormMember(mem: RoomMember) {
        this.$emit(`clickFormMember`, mem)
    }

    clickEditMenu() {}

    showQuestions() {
        this.$vfm.open(`QuestionsBoxModal`)
    }

    updateQuestions() {
        this.$vfm.close(`QuestionsBoxModal`)
        // 表示する
        this.displayParamKeys = this.vrm.displayFields.map(f => f.param_key)
        gtagClick(`投票メンバー アンケート表示`, `${this.displayParamKeys}`)
    }

    goLeft(mem: RoomMember) {
        Logger(`左に移動します: ${Util.output(mem)}`)
        gtagClick(`投票メンバー`, `左に移動`)
        let mems = [...this.vrm.suggestedMembers]
        mems = mems.filter(m => m.user_id != mem.user_id)
        mems.unshift(mem)
        this.vrm.suggestedMembers = mems
    }

    clickCloseRoom(mem: RoomMember) {
        Logger(`終了: ${Util.output(mem)}`)
        gtagClick(`投票メンバー 調整ページ終了`, ``)
        let room = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        this.showRoomModal(room, `delete`)
    }

    changeMemberInfo(mem: RoomMember) {
        // 日程決定者・出席者（必須・オプション）を変更します.
        this.$emit(`changeMemberInfo`, mem)
    }

    openOtherMenuModal(mem: RoomMember) {
        let room = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        this.showMenuModal(room, mem, `shareOther`)
    }

    changeSuggestedDatesDisplayType(mem: RoomMember) {
        let room = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        this.showMenuModal(room, mem, `changePeriod`)
    }

    changeCopyIfFix(mem: RoomMember) {
        // 確定後も投票に残すステータスを変更します.
        this.$emit(`changeCopyIfFix`, mem)
    }

    get canUseChukaiMenu() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_chukai_menu`, false)
    }

    resetFilter() {
        this.vrm.filteredDates = null
        this.vrm.isFiltered = false
    }
}
