
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import Switcher from "packs/components/forms/Switcher.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomSetting from "packs/models/RoomSetting"
import UserSetting from "packs/models/UserSetting"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AppealContent from "packs/models/AppealContent"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import FormCreateManager from "packs/models/FormCreateManager"

@Options({
    components: {
        Switcher,
        TextareaTag,
        TextfieldTag,
        SelectTag,
    },
})
export default class LeftNavPanelPPTosSettingsContent extends Vue {
    // data:

    // image: ヘッダー画像, headerContent: ヘッダーテキスト, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択
    // appo: タイトル/時間, message: オーナーからのメッセージ, pptos: pp/tosの変更
    @Prop()
    panelType: string

    @Prop()
    isPreview: boolean

    @Prop()
    isSuspend: boolean

    // @Prop() // func
    // inputMemo

    @Prop() // func
    linkCal

    @Ref()
    MailEditContent

    @Ref()
    AppointmentInfo

    @Ref()
    FormFieldEditContent

    rm = RoomManager
    rcm = RoomCreateManager
    fcm = FormCreateManager

    groupTitle = ""
    isSP = isMobile(window.navigator).phone

    doesChangeAppo: boolean
    notice = Notice
    Util = Util
    UserPermissionsOption = UserPermissionsOption
    CalendarUtil = CalendarUtil
    saving = false
    // showShareButton: boolean;
    // astag: AvailableScheduleTag = null

    contentWidth = 350

    loading = false
    isDrag = false

    imageUrl: string = null
    beforeUrl: string = null
    currentImageUrl: string = null

    imageTab = `custom`
    calendarTab = `list` // list / settings

    editOrNewForm = ``

    locationNames: string[] = null
    titles: string[] = null

    systemUpdatedAt = Util.getSec()

    inputTitle(e) {
        this.fcm.aform.title = e
    }

    inputTos(e) {
        if (this.fcm?.aform) {
            this.fcm.aform.terms_of_service_url = e
            return
        }
        let _room = Room.copy(this.rcm.room)
        _room.room_setting.terms_of_service_url = e

        this.updateRoomSetting(_room.room_setting)
    }

    inputPP(e) {
        if (this.fcm?.aform) {
            this.fcm.aform.privacy_policy_url = e
            return
        }
        let _room = Room.copy(this.rcm.room)
        _room.room_setting.privacy_policy_url = e

        this.updateRoomSetting(_room.room_setting)
    }

    inputSubmitButtonName(e) {
        if (this.fcm?.aform) {
            this.fcm.aform.form_submit_button_name = e
            return
        }
        let _room = Room.copy(this.rcm.room)
        if (e.length > 20) {
            Notice.message = `送信ボタン名は20文字以内で入力してください。`
            return
        }
        _room.room_setting.form_submit_button_name = e

        this.updateRoomSetting(_room.room_setting)
    }

    updateRoomSetting(setting: RoomSetting) {
        this.rcm.updateRoomSetting(setting)
    }

    inputButtonColor(e) {
        this.fcm.aform.form_submit_button_bg_color = e
    }

    createFieldConverter() {
        this.rcm.showLeftPanelContentWithType = `field_converter`
    }
}
