import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AppealContent from "packs/models/AppealContent"
import FormField from "packs/models/FormField"
import { funcName, Logger } from "packs/common"
import NotificationControl from "packs/utils/NotificationControl"
import RoomMember from "packs/models/RoomMember"
import AnswerConditionGroup from "packs/models/AnswerConditionGroup"
import AnswerCondition from "packs/models/AnswerCondition"

/**
 * 回答内容に応じた振り分け先を指定します.
 */
export default class AssignCondition {
    static readonly DEST_TYPES = {
        public_room: "public_room",
        end_with_message: "end_with_message",
        url: "url",
    }
    static readonly DEST_TYPES_JP = {
        public_room: "公開ページに遷移",
        url: "URLに遷移",
        end_with_message: "メッセージを表示（遷移しない）",
    }

    constructor(
        public id: string,
        public public_id: string,
        public priority,
        public destination_type: string,
        public destination_value: string,
        public answer_condition_groups: AnswerConditionGroup[]
    ) {}

    static copy(o: AssignCondition): AssignCondition {
        // let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        // return clone
        return JSON.parse(JSON.stringify(o))
    }

    static createDefault() {
        let acondg = AnswerConditionGroup.createDefault()
        let pubId = Util.getRandomPublicId(`ASC`)
        return new AssignCondition(null, pubId, null, null, null, [acondg])
    }

    static updateIndexNum(conds: AssignCondition[]) {
        let num = 1
        let _conds = Util.isPresent(conds) ? [...conds] : []
        for (let _c of _conds) {
            _c.priority = num
            num += 1
        }

        return _conds
    }

    static toDescription(cond: AssignCondition, fieldPublicIdDict: { [key: string]: FormField }) {
        if (!cond || !cond.answer_condition_groups || cond.answer_condition_groups.length == 0) {
            return ""
        }

        let desc = ""
        let acgindex = 0
        for (let acg of cond.answer_condition_groups) {
            let acondindex = 0
            for (let acond of acg.answer_conditions) {
                let ff = fieldPublicIdDict[acond.field_public_id]
                if (ff) {
                    desc += acondindex == 0 ? `もし「${ff.field_name}」が` : `でかつ、「${ff.field_name}」が`
                    if (AnswerCondition.TEXT_TYPES.includes(acond.compare_type)) {
                        desc += `${AnswerCondition.TYPE_JP[acond.compare_type]}「${acond.text_value_arr.join(", ")}」`
                    } else if (AnswerCondition.SELECT_TYPES.includes(acond.compare_type)) {
                        desc += `${AnswerCondition.TYPE_JP[acond.compare_type]}「${acond.text_value_arr.join(", ")}」`
                    }
                }
                acondindex += 1
            }
            desc += `の場合、`
            acgindex += 1
        }

        return desc
    }
}
