import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    "modal-id": "AdRegistrationModal",
    "content-class": "modalContent shadowContent rad10 noscroll",
    height: "332",
    width: "400",
    onBeforeClose: _ctx.fadeOutAdModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: "/assets/notifications/golppage.png",
        style: {"width":"100%"},
        class: "pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goLP && _ctx.goLP(...args)))
      })
    ]),
    _: 1
  }, 8, ["onBeforeClose"]))
}