
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import LinkHeaderAssignForm from "packs/components/link/LinkHeaderAssignForm.vue"
import InputAstagNameModal from "packs/pages/link/modals/InputAstagNameModal.vue"
import AstagLists from "packs/pages/link/parts/available_schedule/AstagLists.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import LeftNavPanel from "packs/pages/link/parts/room_settings/LeftNavPanel.vue"
import FormSettings from "packs/pages/link/parts/room_settings/FormSettings.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import AssignForm from "packs/models/AssignForm"
import FormCreateManager from "packs/models/FormCreateManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormField from "packs/models/FormField"
import Room from "packs/models/Room"
import AssignFormCell from "packs/pages/link/settings/AssignFormCell.vue"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        LinkHeaderAssignForm,
        InputAstagNameModal,
        AstagLists,
        RoomsLoader,
        LeftNavPanel,
        FormSettings,
        AssignFormCell,
        OptionModal,
        IconHelp,
    },
    beforeRouteLeave(to, from, next) {
        if (this.fcm.hasUnsaved) {
            if (window.confirm(`編集した内容を保存しますか？`)) {
                // 保存処理
                this.saveForm()
            } else {
                // 変更前のaformの情報に戻します.
                Logger(`元のaformに戻しました: ${Util.output(this.beforeAstag)}`)
                this.rm.aforms = (this.rm.aforms || []).filter(a => a.id != null)

                this.rcm.reset()
                this.fcm.reset()
            }
        } else {
            this.rm.aforms = (this.rm.aforms || []).filter(a => a.id != null)
            this.rcm.reset()
            this.fcm.reset()
        }
        next()
    },
})
export default class AssignForms extends Vue {
    // data:
    isDev = false
    devType = 10 // 0: 未招待ユーザー、1:招待ユーザー 10:利用開始ユーザー
    devdidConnectCal = false

    cm = CalendarManager
    fcm = FormCreateManager
    rcm = RoomCreateManager

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    Util = Util

    beforeAstag: AvailableScheduleTag = null // 保存しないで戻る場合に、もとのastagにastagデータを入れておきます.
    selectedTabName = `list`
    createType = `aform`
    willCreateNewForm = true // 新規で作成するか.（すでに作成済みのastagで投票作成する場合は、falseにします.）

    panelType = null
    loading = false

    headerButtonEnabled = true

    aforms: AssignForm[] = null
    formindex = 0
    editingField: FormField = null
    notice = null

    @Ref()
    header

    // defaultSelectCtagEmails = [
    //     "ja.japanese#holiday@group.v.calendar.google.com"
    // ]; // 日本の祝日.

    beforeMount() {
        $("body").addClass("lskyblueBg")
    }

    public mounted() {
        gtagPage("#/assign_forms")

        this.rm.redirectTopIfChukaiFreePlan()

        if (!this.rm.hasAsTag()) {
            Logger("GET assign_forms")
            this.rm.startProgress()

            /**
             * 既にastagページにいるので、リダイレクトループにならないようチェック不要で取得してきます。
             */
            const checkAstag = false
            let pid = null

            this.rm.getAvailableSchedule(checkAstag, pid).then(result => {
                Logger(`tutorial phase1: ${this.rm.tutorial_phase}`)

                this.getForms()

                this.rm.endProgress()
                // this.buildData(lastAstagId)
                this.rm.redirectTopIfChukaiFreePlan()
            })
            // });
        } else {
            Logger(`tutorial phase2: ${this.rm.tutorial_phase}`)
            Logger(`rmのastag current: ${Util.output(this.rm.astag)}, astag: ${Util.output(this.rm.astag)}`)
            this.getForms()
        }
    }

    canUseAssignForm() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_assign_form`, false)
    }

    getForms() {
        this.rm.getAllAssignForms().then(e => {
            this.aforms = this.rm.aforms

            // rcm.roomを作成しないとFormSettingsが利用できないため、仮をセットします.
            let room = Room.createDefaultRoom()
            this.rcm.setNew(
                room,
                room.current_appointment,
                room.members,
                this.rm.astag,
                this.rm.userInfo,
                this.rm.user_setting,
                ``
            )
        })
    }

    // ページから離れるときは保存したastag情報を削除します。
    public beforeUnmount() {
        Logger(`${funcName()}`)
        $("body").removeClass("lskyblueBg")
    }

    public clickBack() {
        this.$router.back()
    }

    get headerTitle(): string {
        return this.fcm.aform && this.selectedTabName == "detail" ? "振り分けフォームを保存" : null
    }

    /**
     * 作成した調整カレンダーをリモートに保存します。
     */
    public saveForm() {
        Logger(`${funcName()} will save formId: ${this.fcm.aform?.id}`)
        let sendType = "post"

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        this.closePanel()

        let fields = this.rcm.fields
        this.fcm.updateFormFields(fields)

        Logger(`保存するAssignFormの情報: ${Util.output(this.fcm.aform)}`)

        AssignForm.save(this.fcm.aform).then(aform => {
            this.rm.endProgress()
            this.loading = false

            // 保存に成功したら、ローカルに保存した情報は削除しておきます。
            if (aform) {
                this.fcm.aform = aform
                this.rm.aforms
                // let index = this.rm.aforms.findIndex(form => form.id === aform.id)
                let filteredAforms = this.rm.aforms.filter(form => form.id !== aform.id)
                this.rm.aforms = [aform, ...filteredAforms]
                // if (index !== -1) {
                //     this.rm.aforms[index] = aform
                // } else {
                //     this.rm.aforms.push(aform)
                // }
                this.aforms = this.rm.aforms
                this.fcm.hasUnsaved = false
                this.fcm.reset()
                this.header.selectTab(`list`)

                // 送信後、遷移します。
                this.redirectAfterSentPath(sendType)
            }
        })
    }

    public findOrSetAstagsAfterSave(ast: AvailableScheduleTag) {
        let astags = this.rm.astags ? [...this.rm.astags] : []
        let _astag = astags.find(a => a.id == ast.id)
        if (_astag) {
            Object.assign(_astag, ast)
        } else {
            astags.push(this.rm.astag)
        }

        this.rm.astags = astags
    }

    public redirectAfterSentPath(sendType) {
        const currentUrl = window.location.pathname
        Logger(`url: ${currentUrl}`)
        this.selectedTabName = `list`
    }

    public clickNew() {
        gtagClick(`Aform`, `新規作成`)
        if (!this.canUseAssignForm()) {
            // 利用権限がありません
            this.rm.showPlanOptionModalWithName = `振り分けフォーム設定`
            this.$vfm.open("OptionModal")
            return
        }
        this.createType = `aform`

        this.$vfm.open("InputAstagNameModal")
    }

    clickChangeName() {
        this.willCreateNewForm = false
        this.$vfm.open("InputAstagNameModal")
    }

    public editField(field: FormField) {
        // 編集を開始します.
        this.editingField = field
        this.panelType = `field`
        $(".leftNavPanel").addClass("open")
    }

    /**
     * 「項目を追加」を押下したときにパネルを開くアクション.
     */
    public addNewField(formindex: number) {
        this.panelType = `add_field`
        this.formindex = formindex
        $(".leftNavPanel").addClass("open")
    }

    closePanel() {
        $(".leftNavPanel").removeClass("open")
    }

    /**
     * add_fieldによって、fieldを選択した
     */
    public addField(newfield: FormField) {
        if (this.formindex >= 0) {
            let _fields = this.fcm.fields ? [...this.fcm.fields] : []
            _fields.splice(this.formindex, 0, newfield)
            // 番号を振り直します.
            _fields = FormField.updateIndexNum(_fields)
            this.fcm.updateFormFields(_fields)
            this.rcm.updateFormFields(_fields)
        }
        this.editField(newfield)
    }

    createNewAform(astagName: string) {
        Logger(`${funcName()} 新規作成:${astagName}`)
        this.fcm.setNew(this.rm.userInfo)
        this.fcm.createNewForm(astagName)

        this.selectEnd(this.fcm.aform, `aform`)
        this.$vfm.close("InputAstagNameModal")
    }

    /**
     * 一覧からastagを選択してきます。
     */
    public selectEnd(aform: AssignForm, createType: string = null) {
        Logger(`${funcName()} createType:${createType}, this.createType:${this.createType}`)
        if (!this.showNewButton()) {
            // 編集権限がありません
            Notice.message = `編集権限がありません。`
            this.fcm.aform = null
            return
        }

        if (aform.id == null) {
            this.rcm.updateFormFields(this.fcm.fields)
            this.header.selectTab(`detail`)
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        this.rm.getAformDetail(aform?.id).then(res => {
            this.loading = false
            this.rm.endProgress()
            if (!res) return
            Logger(`AssignForms.getAformDetail 取得完了(${createType}) fields:${Util.output(res.form_fields)}`)

            let _aform = res

            if (createType == `copy`) {
                this.fcm.setNew(this.rm.userInfo, `copy`, _aform)
                this.rcm.updateFormFields(this.fcm.fields)
            } else if (createType == `edit`) {
                this.fcm.setNew(this.rm.userInfo, `edit`, _aform)
                this.rcm.updateFormFields(this.fcm.fields)
            }

            Logger(`AssignForms.getAformDetail 確認 fields(${(this.fcm.fields || []).length}):${Util.output(this.fcm.fields)}`)

            this.createType = `aform`
            if (createType) {
                this.createType = createType
            }

            this.header.selectTab(`detail`)
        })
    }

    @Watch("rcm.showLeftPanelContentWithType")
    updateEditEditableContent() {
        Logger(`updateEditEditableContent: ${this.rcm.showLeftPanelContentWithType}`)
        if (Util.isBlank(this.rcm.showLeftPanelContentWithType)) return
        this.editEditableContent(this.rcm.showLeftPanelContentWithType)
        this.rcm.showLeftPanelContentWithType = null
    }

    /**
     * 子要素からどの要素を編集するかをはめて、パネルを表示します。
     * image: ヘッダー画像, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択, pptos変更, thanks_page 完了ページを編集
     * @param type [string]
     */
    public editEditableContent(type: string) {
        if (!type) return
        if (this.panelType == type) return

        this.panelType = type
        $(".leftNavPanel").addClass("open")
    }

    showNewButton() {
        if (Const.suspend(this.rm)) return false

        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_create_available_schedule_tag`)
    }

    editPage(aform: AssignForm) {
        Logger(`${funcName()} aform: ${Util.output(aform)}`)

        this.selectEnd(aform, `edit`)
    }

    copyPage(aform: AssignForm) {
        Logger(`${funcName()} aform:${Util.output(aform)}`)

        this.selectEnd(aform, `copy`)
    }

    showCloseRoomModal(aform: AssignForm) {
        Logger(`${funcName()} aform:${aform?.id}`)
        // this.$vfm.open("CloseRoomModal")
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        if (confirm(`削除した場合、元に戻せません。`)) {
            if (aform.id == null) {
                Logger(`削除したいaform: ${Util.output(aform)}`)
                this.aforms = this.aforms.filter(a => a.id != null && a.title != aform.title)
                this.rm.endProgress()
                this.rm.aforms = this.aforms
                this.loading = false
                return
            }

            AssignForm.delete(aform).then(res => {
                this.rm.endProgress()
                this.loading = false
                if (res) {
                    this.aforms = this.aforms.filter(ast => ast.id != aform.id)
                    this.rm.aforms = this.aforms
                    this.fcm.hasUnsaved = false
                    this.fcm.reset()
                }
            })
        }
    }

    selectTab(tabname: string) {
        Logger(`${funcName()} selectTab:${tabname} `)
        this.selectedTabName = tabname
    }

    clickOK() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} 課金リクエスト`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.rm.showPlanOptionModalWithName} 課金リクエストがありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                Notice.message = e.data.message
                this.rm.showPlanOptionModalWithName = null
            } else {
                Notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} キャンセル 課金リクエスト`, ``)
        this.rm.showPlanOptionModalWithName = null
        this.$vfm.close("OptionModal")
    }

    closeOptionModal() {
        this.rm.showPlanOptionModalWithName = null
        this.$vfm.close("OptionModal")
    }
}
