
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import Util from "packs/utils/Util"
import draggable from "vuedraggable"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import InputGroupNamespaceModal from "packs/pages/link/modals/InputGroupNamespaceModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import ConditionSettings from "packs/pages/link/parts/room_settings/ConditionSettings.vue"
import SearchRoomModal from "packs/pages/link/parts/room_settings/room_settings_parts/SearchRoomModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import FormField from "packs/models/FormField"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormCreateManager from "packs/models/FormCreateManager"

@Options({
    components: {
        UserIcon,
        MdTextfieldTag,
        InputGroupNamespaceModal,
        CheckboxTag,
        SelectTag,
        FormFieldContent,
        draggable,
        ConditionSettings,
        SearchRoomModal,
    },
})
export default class FormSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    @Prop() // func
    editEditableContent

    @Prop() // room_setting / aform
    viewFrom: string

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    fcm = FormCreateManager

    Util = Util

    fields: FormField[] = null
    systemUpdatedAt = Util.getSec()
    drag: boolean = false

    selectedTabName = `list` // list / conditions

    created() {}

    mounted() {
        this.updateRoom()

        // デフォルトで開いておきます.
        this.addNewField((this.fields || []).length)
    }

    @Watch("rcm.fields", { deep: true })
    updateRoom() {
        if (!this.rcm.fields) return

        this.fields = this.rcm.realFields()

        Logger(`updateRoom: FormSettings: fields: ${Util.output(this.fields)}`)
        // this.systemUpdatedAt = Util.getSec()
    }

    updateFieldFromChild() {}

    editField(field: FormField) {
        // 左パネルを開きます.
        this.$emit(`editField`, field)
    }

    removeField(field: FormField) {
        // フィールドを削除します.
        let ffs = [...this.rcm.fields]
        let _ffs = ffs.filter(_f => _f.index_num != field.index_num)
        this.rcm.updateFormFields(_ffs)
        if (this.fcm?.aform) {
            this.fcm.updateFormFields(_ffs)
        }
        // this.rcm.room.form_fields = FormField.updateIndexNum(_ffs)
    }

    copyField(field: FormField) {
        let ffs = [...this.rcm.fields]
        let _ff = FormField.copy(field)
        let publicId = FormUtil.generateRamdom()
        _ff.name_attr = null
        _ff.public_id = publicId
        ffs.push(_ff)
        // 番号を振り直します.
        this.fields = FormField.updateIndexNum(ffs)
        this.updateFields(this.fields)
    }

    clickUp(field: FormField) {
        // 順番を並び替えます.
        let ffs = [...this.rcm.fields]
        let _fIndex = ffs.findIndex(f => f.index_num == field.index_num)

        ffs.splice(_fIndex, 1)
        ffs.splice(_fIndex - 1, 0, field)
        // 番号を振り直します.
        this.fields = FormField.updateIndexNum(ffs)

        this.updateFields(this.fields)
    }

    clickDown(field: FormField) {
        // 順番を並び替えます. [0,1,2,3,4]
        let ffs = [...this.rcm.fields]
        // let newFfs = ffs.filter(f => f.index_num!=field.index_num)
        let _fIndex = ffs.findIndex(f => f.index_num == field.index_num)
        ffs.splice(_fIndex, 1)
        ffs.splice(_fIndex + 1, 0, field)
        // 番号を振り直します.
        this.fields = FormField.updateIndexNum(ffs)
        this.updateFields(this.fields)
    }

    addNewField(fieldindex: number) {
        Logger(`${fieldindex}番目の後ろに追加します.`)
        // this.fields.splice(fieldindex,0, newField)
        this.$emit(`addNewField`, fieldindex + 1)
    }

    editTOSPP() {
        this.editEditableContent(`pptos`)
    }

    hasTos() {
        return Util.isUrl(this.rcm?.room?.room_setting?.terms_of_service_url) || Util.isUrl(this.fcm?.aform?.terms_of_service_url)
    }

    hasPP() {
        return Util.isUrl(this.rcm?.room?.room_setting?.privacy_policy_url) || Util.isUrl(this.fcm?.aform?.privacy_policy_url)
    }

    sortEnd() {
        this.drag = false
        let ffs = [...this.rcm.fields]
        this.fields = FormField.updateIndexNum(ffs)
        this.updateFields(this.fields)
    }

    updateFields(fields: FormField[]) {
        this.rcm.updateFormFields(fields)
    }

    editConditions() {
        if (this.selectedTabName == `list`) {
            this.selectedTabName = `conditions`
        } else {
            this.selectedTabName = `list`
        }
    }

    formSubmitButtonName() {
        if (this.viewFrom == `aform` && Util.isPresent(this.fcm.aform.form_submit_button_name)) {
            return this.fcm.aform.form_submit_button_name
        }

        if (Util.isPresent(this.rcm.room_setting.form_submit_button_name)) {
            return this.rcm.room_setting.form_submit_button_name
        }

        return `送信`
    }

    hasColor() {
        if (this.viewFrom == `aform` && Util.isPresent(this.fcm?.aform?.form_submit_button_bg_color)) {
            return true
        }

        return false
    }

    buttonBgColor() {
        if (this.viewFrom == `aform` && Util.isPresent(this.fcm?.aform?.form_submit_button_bg_color)) {
            return `#${this.fcm.aform.form_submit_button_bg_color}`
        }

        return null
    }

    openSearchRoomModal() {
        if ((this.fcm.conds || []).length == 0) {
            this.$vfm.open(`SearchRoomModal`)
            return
        }

        if (
            (this.fcm.conds || []).length > 0 &&
            confirm(`公開ページのフォームを読み込みますか？すでに設定したルールはリセットされます。`)
        ) {
            this.$vfm.open(`SearchRoomModal`)
            return
        }
    }

    selectCopiedForm() {
        Logger(`${funcName()}`)
    }
}
