import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalInnerPaymentRequest = _resolveComponent("ModalInnerPaymentRequest")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueFinalModal, {
      name: "AdPromBusinessModal",
      "content-class": "modalContent shadowContent rad10 noscroll",
      onBeforeClose: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        (!_ctx.showForm)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: "/assets/notifications/prom_business_plan.png",
              style: {"width":"100%"},
              class: "pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showUpgradeForm && _ctx.showUpgradeForm(...args)))
            }))
          : (_openBlock(), _createBlock(_component_ModalInnerPaymentRequest, {
              key: 1,
              onHide: _ctx.fadeOutAdModal,
              type: `upgrade`
            }, null, 8, ["onHide"]))
      ]),
      _: 1
    }, 8, ["onBeforeClose"])
  ]))
}