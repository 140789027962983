import RoomMember from "./RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"
import Appointment from "./Appointment"
import CalendarTag from "./CalendarTag"

export default class LocationConverter {
    constructor(
        public id: string,
        public location_converter_id: string,

        public status: number,
        public convert_type: string, // default / keyword_to_blank
        public before_keyword: string, // オンライン
        public after_keyword: string, // オンライン
        public public_id: string,

        public lat: number,
        public lon: number,

        public selected: boolean,
        public index_num: number
    ) {}

    static createDefault(): LocationConverter {
        let now = Util.getSec()
        return new LocationConverter(null, null, 10, `default`, ``, ``, null, null, null, false, 0)
    }

    static fetchFromJson(loctagsJson: any): LocationConverter[] {
        let loctags = []
        if (!loctagsJson || loctagsJson.length == 0) return []

        for (let loctag of loctagsJson) {
            const _loctag: LocationConverter = loctag
            loctags.push(_loctag)
        }
        return loctags
    }

    /**
     * 招待したことのあるユーザーを取得してきます。
     */
    static getLocations(): Promise<LocationConverter[]> {
        return axios
            .get(`${Util.prefixUrl}/user_settings/location_converters`)
            .then(res => {
                const _locations = res.data.location_converters
                if (!_locations) return []
                let loctags = LocationConverter.fetchFromJson(_locations)
                return loctags
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static save(conv: LocationConverter, sendType): Promise<LocationConverter> {
        let endpoint = ""

        // appointment["parent_room_id"] = ""
        Logger(`sendType: ${sendType} appo: ${Util.output(conv)}`)

        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/user_settings/location_converters`,
            data: conv,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const _conv: LocationConverter = LocationConverter.fetchFromJson([res.data.location_converter])[0]

                return _conv
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static delete(loctag: LocationConverter) {
        let endpoint = `location_converters`

        let params = { id: loctag.location_converter_id }

        return axios
            .delete(`${Util.prefixUrl}/user_settings/${endpoint}`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`

                const _conv: LocationConverter = LocationConverter.fetchFromJson([res.data.location_converter])[0]
                if (_conv) {
                    return _conv
                } else {
                    return null
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
