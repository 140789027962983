
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import axios from "axios"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import UserFile from "packs/models/UserFile"
import NotificationControl from "packs/utils/NotificationControl"

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class ModalUpload extends Vue {
    @Prop()
    type: string // addressbook/room

    // data
    isSP = isMobile(window.navigator).phone
    appos = null
    rm = RoomManager

    isDrag: string = null
    fileForm: any = null
    loading = false

    public dismissModal() {
        this.$vfm.close("UploadModal")
    }

    checkDrag(event, key, status) {
        Logger(`event.dataTransfer.types: ${Util.output(event.dataTransfer.types)}`)
        // if (status && event.dataTransfer.types != "text/plain") {
        //     //ファイルではなく、html要素をドラッグしてきた時は処理を中止
        //     return false;
        // }
        this.isDrag = status ? key : null
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)
        if (this.loading) {
            Notice.message = `現在解析中です。`
            return false
        }
        this.isDrag = null

        let params = UserFile.createFileParams(event, `public_image`)[0]
        if (!params) return

        this.loading = true

        let path = this.type == `room` ? `create_rooms` : `upsert_contact_list`

        axios
            .post(`${Util.prefixUrl}/file_uploaders/${path}`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                event.target.value = null
                event = null
                this.loading = false
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                if (res.data.result == `ok`) {
                    this.$emit("uploadedFile")
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                event.target.value = null
                event = null
                this.loading = false
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    /**
     * @param suffix [string] csv/xlsx
     */
    downloadCSV(suffix: string) {
        // let suffix = `xlsx`
        let endpoint = ``
        if (this.type == `room`) {
            endpoint = suffix == `csv` ? `download_tmp_create_room` : `download_tmp_create_room_excel`
        } else {
            endpoint = suffix == `csv` ? `download_tmp_upsert_contact_list` : `download_tmp_upsert_contact_list_excel`
        }
        let filename =
            this.type == `room` ? `調整ページ一括作成用テンプレート.${suffix}` : `アドレス帳一括登録用テンプレート.${suffix}`
        axios
            .request({ url: `${Util.prefixUrl}/file_uploaders/${endpoint}`, responseType: "blob" })
            .then(res => res.data)
            .then(blob => {
                this.download(blob, filename)
                return null
            })
    }

    download(blob, csvName: string) {
        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = csvName
        link.click()
    }
}
