
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import FixVotedRoomsModal from "packs/pages/link/parts/room_summary/FixVotedRoomsModal.vue"
import SuggestedRoomsMembersHeader from "packs/pages/link/parts/room_summary/SuggestedRoomsMembersHeader.vue"
import SuggestedRoomsMembersTable from "packs/pages/link/parts/room_summary/SuggestedRoomsMembersTable.vue"
import ChangeMemberInfoModal from "packs/pages/link/parts/room_summary/ChangeMemberInfoModal.vue"
import ImportFromCalendarModal from "packs/pages/link/parts/room_summary/ImportFromCalendarModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import FileUtil from "packs/utils/FileUtil"
import LocationTag from "packs/models/LocationTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
        FixVotedRoomsModal,
        SuggestedRoomsMembersHeader,
        SuggestedRoomsMembersTable,
        ChangeMemberInfoModal,
        ImportFromCalendarModal,
    },
})
export default class SuggestedRooms extends Vue {
    // data:
    @Prop()
    rooms: Room[]

    @Prop()
    room: Room // PublicRoom

    @Prop() // func
    showRoomModal

    @Prop() // func
    showMenuModal

    @Ref()
    FixVotedRoomsModal

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone
    currentRoom: Room = null

    // loading = false
    Util = Util

    pdm = PossibleDatesManager
    member: RoomMember = null

    public mounted() {
        Logger(`${funcName()}`)
        // gtagPage(`#/summary`)
        // this.room = this.rm.currentRoom;
        this.updateRooms()

        this.updateScrollContentRule()
    }

    goRoom(room: Room) {
        this.$emit(`goRoom`, room)
    }

    @Watch(`rooms`, { deep: true })
    updateRooms() {
        if (!this.rooms) return
        if (!this.vrm) return

        // 既にメンバーがセットされている場合、リフレッシュしません.
        // リフレッシュしてしまうと並べ替えた情報が戻ってしまうため.
        // 再調整した場合などリフレッシュさせたい場合は、publicRoomをnullにしてください。
        if (this.vrm.publicRoom) return
        Logger(`${funcName()}`)

        this.vrm.createNew(this.room, this.rooms, this.rm.userInfo)
        if (!this.vrm.didConflictCheck) {
            this.vrm.didConflictCheck = true
            this.vrm.conflictCheck().then(res => {
                Logger(`vrm.conflictCheck res:${Util.output(res)}`)
            })
        }
    }

    updateScrollContentRule() {
        Logger(`${funcName()}`)

        $(".MembersHeader").on("scroll", function () {
            const _left = $(this).scrollLeft()
            $(".MembersVotedInfo")[0].scrollLeft = _left
            // Logger(`scrollHeader: ${_left}`)
        })

        $("#MembersVotedInfo").on("scroll", function () {
            const _left = $(this).scrollLeft()
            $(".MembersHeader")[0].scrollLeft = _left
            // Logger(`scrollContent: ${_left}`)
        })
    }

    confirmDate(pd: PossibleDate) {
        Logger(`pdを確認`)
        gtagClick(`この日程を確認ボタンvote`, `${pd.jp_format}`)
        this.pdm.possibleDates = [...this.vrm.suggestedDates]
        this.pdm.selectCurrentPd(pd)
        this.$vfm.open(`FixVotedRoomsModal`)
    }

    clickCancel() {
        this.$vfm.close(`FixVotedRoomsModal`)
    }

    // 確定後、モーダルを閉じます.
    fixedAppo(fixedRooms: Room[], notFixedRooms: Room[]) {
        Logger(`${funcName()} fixedRooms:${Util.output(fixedRooms)}`)
        this.$vfm.close(`FixVotedRoomsModal`)
        this.$emit(`fixedAppo`, fixedRooms, notFixedRooms)
    }

    clickFormMember(mem: RoomMember) {
        // フォーム確認
        this.$emit(`clickFormMember`, mem)
    }

    clickCloseRoom(mem: RoomMember) {
        this.showRoomModal(this.room, "delete")
    }

    downloadCSV() {
        let csvHeader = [null, null]
        // let csvForm = [null, null]
        let csvContent: any[] = [csvHeader]
        let ffs = this.vrm.publicRoom.form_fields
        let arrayLength = Object.values(ffs).length

        // let pubRoom = this.vrm.publicRoom
        // let ffs = pubRoom.form_fields
        // // let fieldIds = ffs.map(f => f.field_id)
        // let arrayLength = ffs.length

        // 二次元マトリックスでシート上にそれぞれ入力していきます.（横軸： 日程、可能人数 + 投票者の数  縦軸: フォーム設問数）
        let csvForm = new Array(arrayLength).fill(new Array(this.vrm.suggestedMembers.length + 2))

        let ansIndex = 0
        for (let ff of ffs) {
            let arr = csvForm[ansIndex]
            arr[0] = ff.field_name
            csvForm[ansIndex] = [...arr]
            ansIndex++
        }

        Logger(`arrayLength: ${arrayLength}, csvForm: ${Util.output(csvForm)}`)

        let memIndex = 0
        for (let _mem of this.vrm.suggestedMembers) {
            if (Util.isPresent(_mem.company_name)) {
                csvHeader.push(`${_mem.name}(${_mem.company_name})`)
            } else {
                csvHeader.push(`${_mem.name}`)
            }

            // let values = []
            let ansIndex = 0
            for (let [key, value] of Object.entries(_mem.answer_results)) {
                let valueDic = value as any
                Logger(`[${ansIndex}][${memIndex + 2}] value:${valueDic.value}`)
                let arr = csvForm[ansIndex]

                // フォームの設問が変わった場合、エラーになってしまうため、ブレイクする. refs #4202
                if (ansIndex >= arrayLength) break

                arr[memIndex + 2] = valueDic.value
                csvForm[ansIndex] = [...arr]
                ansIndex++
            }
            // for (let [key, value] of Object.entries(_mem.answer_results)) {
            //     let valueDic = value as any
            //     let formIndex = fieldIds.indexOf(valueDic.field_id)
            //     if (formIndex == -1) continue

            //     Logger(`CSV[${formIndex}][${memIndex + 2}] value:${valueDic.value}`)
            //     let arr = csvForm[formIndex]

            //     // refs #4202
            //     arr[memIndex + 2] = valueDic.value
            //     csvForm[formIndex] = [...arr]
            //     ansIndex++
            // }
            Logger(`csvForm: ${Util.output(csvForm)}`)
            memIndex++
        }
        Array.prototype.push.apply(csvContent, csvForm)

        this.vrm.suggestedDates.forEach((_pd, pdindex) => {
            let _num = this.vrm.numberList[_pd.start_time]
            let arr = [_pd.jp_format, `${_num}人`]
            for (let _mem of this.vrm.suggestedMembers) {
                let findPd = _mem.suggested_dates.find(sd => sd.start_time == _pd.start_time)
                if (findPd) {
                    arr.push(`○`)
                } else {
                    arr.push(``)
                }
            }
            csvContent.push(arr)
        })
        let bom = new Uint8Array([0xef, 0xbb, 0xbf])
        let blobdata = csvContent.map(c => c.join(`,`)).join(`\r\n`)
        // .join(`\r\n`)
        let blob = new Blob([bom, blobdata], { type: "text/csv" })
        FileUtil.download(blob, `waaq_vote${Util.getSec() * 1000}.csv`)
    }

    changeMemberInfo(mem: RoomMember) {
        this.member = mem
        this.currentRoom = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        this.$vfm.open(`ChangeMemberInfoModal`)
    }

    changeCopyIfFix(mem: RoomMember) {
        // this.member = mem
        let room = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        if (room) {
            Room.changeCopyStatus(room).then(_room => {
                if (_room) {
                    Logger(`${funcName()} `)
                    room.priority_type = _room.priority_type
                    mem.priorityType = _room.priority_type
                }
            })
        }
    }

    // 更新が完了したら戻ってきます.
    updateMemberInfo(mem: RoomMember) {
        this.member = mem
        this.currentRoom = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        let member = this.currentRoom.members.find(m => m.user_id == mem.user_id)
        member.assign_type = mem.assign_type
        member.role = mem.role
        member.role_name = mem.role_name
        this.member = member
    }

    openImportFromCalendarModal() {
        Logger(`${funcName()}`)
        this.$vfm.open(`ImportFromCalendarModal`)
    }
}
