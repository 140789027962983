import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: `${_ctx.is_white ? `/assets/icons/lock` : `/assets/icons/lock_grey`}`,
    width: _ctx.width ? _ctx.width : 12,
    height: _ctx.width ? _ctx.width : 12,
    class: "mr5",
    style: {"margin-top":"-3px"}
  }, null, 8, _hoisted_1))
}