import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  src: "/assets/icons/bikkuri_yellow",
  width: "24",
  class: "mt15"
}
const _hoisted_2 = {
  key: 1,
  src: "/assets/icons/circle_bold",
  width: "24",
  class: "mt15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.mem && _ctx.myPd)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`centered w100 posRe ${_ctx.myPd?.conflict_info?.is_conflict ? `lgreyBg` : ``}`),
        style: {"height":"100%"}
      }, [
        _createElementVNode("div", null, [
          (_ctx.myPd.conflict_info ? _ctx.myPd.conflict_info.is_conflict : false)
            ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_1, null, 512)), [
                [_directive_tooltip, `${_ctx.mem.name}様のカレンダーで確定した予定があります`]
              ])
            : (_openBlock(), _createElementBlock("img", _hoisted_2))
        ])
      ], 2))
    : _createCommentVNode("", true)
}